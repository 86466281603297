import dopegood from '../assets/covers/projects-dopegood.jpg'
import newdopegood from '../assets/covers/projects-newdopegood.jpg'
import amarc from '../assets/covers/projects-am-arc.jpg'
import vim from '../assets/covers/projects-vimcosmo.jpg'
import dopop from '../assets/covers/projects-dopop.jpg'
// import comfeey from '../assets/covers/comfeey.webp'
import {vim1, vim2, vim3, vim4, vim5, vim6,vim7,vim8,vim9} from "../assets/covers/vim"
import {a1, a2, a3, a4, a5, a6, a7} from "../assets/covers/am"
import {do1, do2, do3, do4, do5, do6, do7,do8,do9,do10} from "../assets/covers/dopop"
import {d1, d2, d3, d4, d5, d6, d7, d8, d9} from "../assets/covers/dopegood"
import { nd1, nd2, nd3, nd4, nd5, nd6, nd7, nd8, nd9, nd10, nd11, nd12 } from "../assets/covers/newdopegood"


export const prjData = [
  
  {
    id:"p15i11",
    name: 'VimCosmo',
    desc: "Beauty brand, e-commerce",
    tags:['Interior',],
    family:"vimcosmo",
    elements:[
      {
        id:"vt11",
        type:"textbox",
        h3:"Vimcosmo",
        mt:true,
        h1:"Beauty brand, e-commerce",
        width:"short",
        alignment:"alignment",
      },
      {
        id:"vc11",
        mt:true,
        type:"cover",
        src:vim1,
        double:false,
        width:"full-w",
        height:"full-H",
      },
      {
        id:"vt21",
        type:"textbox",
        h3:"Expectaion",
        pi:"Our designer’s main objective was to catch users’ attention to the products and encourage them to purchase. Therefore, every page of the website features a product layout. In addition, we wanted to ensure that everything, from images to color schemes, meets the client’s needs and is consistent with their branding.",
        width:"long",
        butt:"https://vimcosmo.com/",
      },
      {
        id:"vb11",
        type:"butsec",
        url:"https://vimcosmo.com/",
        width:"full-w",
        height:"full-H",
        src:[vim2, vim],
      },
      {
        id:"vc31",
        type:"cover",
        double:true,
        align:"space between",
        width:"full-w",
        src:[vim3, vim4],
        h2:"Front-end development",
        h2width:"full",
      },
      {
        id:"ws2",
        type:"whitespace",
        height:"25vw",
      },
      {
        id:"vt31",
        type:"textbox",
        pi:"To make it easier for our client to add, delete, or update content, we developed a content management system.",
        width:"long",
        alignment:"vw3",
      },
      {
        id:"vc41",
        type:"cover",
        double:false,
        src:vim5,
        width:"full-w",
        height:"full-H",
      },
      {
        id:"vcmb11",
        type:"cover",
        src:vim6,
        double:false,
        width:"full-w mb",
        height:"full-H",
      },
      {
        id:"vt41",
        type:"textbox",
        double:true,
        h2width:"short",
        h2:"Fast And Responsive Design",
        pi:"Our mobile-friendly websites are designed to adapt to different devices, so Vimcosmo is no exception. Since the site’s contents are minimal, we used multiple engaging animations to keep users entertained. The website is very light and fast, with a file size of only 5MB.",
      },  
      {
        id:"vc51",
        type:"cover",
        src:vim6,
        double:false,
        pb:"pd",
        width:"full-w",
        height:"full-H",
        // end:true,
      },
      {
        id:"vc61",
        type:"cover",
        src:vim7,
        pb:"pd",
        double:false,
        width:"full-w",
        height:"full-H",
        // end:true,
      },
      {
        id:"vc71",
        type:"cover",
        src:vim8,
        pb:"pd",
        double:false,
        width:"full-w",
        height:"full-H",
        // end:true,
      },
      {
        id:"vc81",
        type:"cover",
        src:vim9,
        pb:"pd",
        double:false,
        width:"full-w",
        height:"full-H",
        end:true,
      },
    ],
    a:{text:"View more", url:"vimcosmo"},
    img:{
      cover:"cover",
      colors:{right: "#000000", left:"#CC669Bbf"},
      url:vim
    }
  },
  {
    id:"p15i33",
    name: 'Dopop',
    desc: "NFT Marketplace",
    tags:['Interior', '3D Rendering','3DRendering',],
    family:"dopop",
    elements:[
      {
        id:"vt13",
        type:"textbox",
        h3:"Dopop",
        mt:true,
        h1:"Online marketplace to create, buy and sell NFTs",
        width:"short",
        alignment:"alignment",
      },
      {
        id:"vc13",
        mt:true,
        type:"cover",
        src:do1,
        double:false,
        width:"full-w",
        height:"full-H",
      },
      {
        id:"vt23",
        type:"textbox",
        h3:"Expectaion",
        pi:"We aimed to design, develop and build a secure NFT marketplace with a modern, colorful, and user-friendly interface that would appeal to any crypto enthusiast, whether they were new or experienced.",
        butt:"https://dopop.net",
        width:"long",
      
      },
      // {
      //   id:"vc23",
      //   type:"cover",
      //   double:false,
      //   src:do2,
      //   width:"medium-w",
      //   height:"medium-h",
      // },
      // {
      //   id:"vb13",
      //   type:"button",
      //   // double:false,
      //   url:"https://dopop.net",
      //   width:"medium-w",
      //   height:"medium-h",
      // },
      {
        id:"vb11",
        type:"butsec",
        // double:false,
        url:"https://dopop.net",
        width:"full-w",
        height:"full-H",
        src:[do2,dopop],
      },
      {
        id:"vc33",
        type:"cover",
        double:true,
        align:"space between",
        src:[do3,do4],
        width:"full-w",
        h2:"Front-end development",
        h2width:"full",
      },
      {
        id:"vt33",
        type:"textbox",
        pi:"Dopop has a dark and light theme featuring the most popular trendy design styles. Since the design shared many similar components, we have made reusable components in the front end to use across the website and quickly transfer data between them.",
        width:"long"
      },
      {
        id:"vc53",
        type:"cover",
        double:true,
        align:"space between",
        src:[do6, do5],
        align:"normal",
      },
      {
        id:"ws1",
        type:"whitespace",
        height:"66vw",
      },
      {
        id:"vt43",
        type:"textbox",
        double:true,
        h2:"Technologies",
        pi:"React was the perfect fit for achieving this and managing data efficiently. Furthermore, we used GSAP and Lottie to create smooth animations.",
      },  
      {
        id:"vc43",
        type:"cover",
        double:false,
        src:do7,
        width:"full-w",
        height:"full-H",
        pb:"pd",
        // end:true,
      },
      {
        id:"vc63",
        type:"cover",
        double:false,
        src:do8,
        width:"full-w",
        height:"full-H",
        pb:"pd",
        // end:true,
      },
      {
        id:"vc73",
        type:"cover",
        double:false,
        src:do9,
        width:"full-w",
        height:"full-H",
        pb:"pd",
        // end:true,
      },
      {
        id:"vc83",
        type:"cover",
        double:false,
        src:do10,
        width:"full-w",
        height:"full-H",
        pb:"pd",
        end:true,
      },
    ],
    a:{text:"View more", url:"dopop"},
    img:{
      cover:"",
      colors:{right: "#000000", left:"#808CFFbf"},
      url:dopop
    }
  },
  {
    id:"g343hn3",
    name: 'Dopegood New',
    desc: 'Furniture brand, e-commerce',
    tags:['3D Model'],
    family:"newdopegood",
    elements:[
      {
        id:"3g332tg",
        type:"textbox",
        h3:"Dopegood",
        mt:true,
        h1:"Furniture Brand, Online Store",
        width:"short",
        alignment:"alignment",
      },
      {
        id:"2f34t3",
        mt:true,
        type:"cover",
        double:false,
        src:nd1,
        width:"full-w",
        height:"full-H",
      },
      {
        id:"f2f44",
        type:"textbox",
        h3:"Expectaion",
        pi:"Creating the brand identity, logo design, website design, and web development for a modern furniture brand was requested. The overall design matched the gorgeous modern furniture, and the online e-commerce website had to be fully customizable.",
        width:"long",
        butt:"https://dopegood.com",
      
      },
      // {
      //   id:"vc24",
      //   type:"cover",
      //   double:false,
      //   src:d2,
      //   width:"medium-w",
      //   height:"medium-h",
      // },
      // {
      //   id:"vb14",
      //   type:"button",
      //   // double:false,
      //   url:"https://dopegood.com",
      //   width:"medium-w",
      //   height:"medium-h",
      // },
      {
        id:"h665",
        type:"butsec",
        // double:false,
        url:"https://dopegood.com",
        width:"full-w",
        height:"full-H",
        src:[nd2,newdopegood],
      },
      {
        id:"d1321",
        type:"cover",
        double:true,
        src:[nd3,nd4,nd5],
        align:"space between",
        width:"full-w",
        h2:"Custom 3D Models",
      },
      {
        id:"24g24",
        type:"textbox",
        pi:"Our web application was created by integrating Redux with React to centralized data management in the front-end technology. With React as the front-end technology, we were able to create an interactive storefront and modernize the website to maximize performance.",
        width:"long"
      },
      {
        id:"verg2",
        type:"cover",
        double:false,
        src:nd6,
        width:"full-w",
        height:"full-H",
      },
      {
        id:"vsrer3",
        type:"textbox",
        double:true,
        h2width:"short",
        h2:"How we approached designing the API",
        pi:"We decided to go with Nodejs and TypeScript for the API. Since NodeJs offers a wide range of tools and is a prevalent backend technology that is easy to integrate into any system design. Using TypeScript also enables us to gain type control and facilitate future debugging. Also, we used MongoDB to maximize the speed.",
      },  
      {
        id:"g342v2",
        type:"cover",
        double:false,
        src:nd7,
        pb:"pd",
        // align:"normal",
        width:"full-w",
        height:"full-H",
      },
      {
        id:"egw43",
        type:"cover",
        double:false,
        src:nd8,
        pb:"pd",
        // align:"normal",
        width:"full-w",
        height:"full-H",
      },
      {
        id:"sb23",
        type:"cover",
        double:false,
        src:nd9,
        pb:"pd",
        // align:"normal",
        end:true,
        width:"full-w",
        height:"full-H",
      },
      {
        id:"vdsr445",
        type:"cover",
        double:false,
        src:nd10,
        pb:"pd",
        // align:"normal",
        end:true,
        width:"full-w",
        height:"full-H",
      },
      {
        id:"sdag3g",
        type:"cover",
        double:false,
        src:nd11,
        pb:"pd",
        // align:"normal",
        end:true,
        width:"full-w",
        height:"full-H",
      },
      {
        id:"dh172gh1",
        type:"cover",
        double:false,
        src:nd12,
        pb:"pd",
        // align:"normal",
        end:true,
        width:"full-w",
        height:"full-H",
      },
    ],
    a:{text:"View more", url:"newdopegood"},
    img:{
      cover:"",
      colors: {right: "#000000", left:"#FF9673bf"},
      url:newdopegood
    }
  },
  {
    id:"p15i44",
    name: 'Dopegood',
    desc: 'Furniture brand, e-commerce',
    tags:['3D Model'],
    family:"dopegood",
    elements:[
      {
        id:"vt14",
        type:"textbox",
        h3:"Dopegood",
        mt:true,
        h1:"Modern home furniture store",
        width:"short",
        alignment:"alignment",
      },
      {
        id:"vc14",
        mt:true,
        type:"cover",
        double:false,
        src:d1,
        width:"full-w",
        height:"full-H",
      },
      {
        id:"vt24",
        type:"textbox",
        h3:"Expectaion",
        pi:"Creating the brand identity, logo design, website design, and web development for a modern furniture brand was requested. The overall design matched the gorgeous modern furniture, and the online e-commerce website had to be fully customizable.",
        width:"long",
        butt:"https://dopegood.com",
      
      },
      // {
      //   id:"vc24",
      //   type:"cover",
      //   double:false,
      //   src:d2,
      //   width:"medium-w",
      //   height:"medium-h",
      // },
      // {
      //   id:"vb14",
      //   type:"button",
      //   // double:false,
      //   url:"https://dopegood.com",
      //   width:"medium-w",
      //   height:"medium-h",
      // },
      {
        id:"vb11",
        type:"butsec",
        // double:false,
        url:"https://dopegood.com",
        width:"full-w",
        height:"full-H",
        src:[d2,dopegood],
      },
      {
        id:"vc34",
        type:"cover",
        double:true,
        src:[d3,d4],
        align:"space between",
        width:"full-w",
        h2:"Front-end development",
      },
      {
        id:"vt34",
        type:"textbox",
        pi:"Our web application was created by integrating Redux with React to centralized data management in the front-end technology. With React as the front-end technology, we were able to create an interactive storefront and modernize the website to maximize performance.",
        width:"long"
      },
      {
        id:"vc44",
        type:"cover",
        double:false,
        src:d5,
        width:"full-w",
        height:"full-H",
      },
      {
        id:"vt44",
        type:"textbox",
        double:true,
        h2width:"short",
        h2:"How we approached designing the API",
        pi:"We decided to go with Nodejs and TypeScript for the API. Since NodeJs offers a wide range of tools and is a prevalent backend technology that is easy to integrate into any system design. Using TypeScript also enables us to gain type control and facilitate future debugging. Also, we used MongoDB to maximize the speed.",
      },  
      {
        id:"vc54",
        type:"cover",
        double:false,
        src:d6,
        pb:"pd",
        // align:"normal",
        width:"full-w",
        height:"full-H",
      },
      {
        id:"vc64",
        type:"cover",
        double:false,
        src:d7,
        pb:"pd",
        // align:"normal",
        width:"full-w",
        height:"full-H",
      },
      {
        id:"vc74",
        type:"cover",
        double:false,
        src:d8,
        pb:"pd",
        // align:"normal",
        end:true,
        width:"full-w",
        height:"full-H",
      },
      {
        id:"vc74",
        type:"cover",
        double:false,
        src:d9,
        pb:"pd",
        // align:"normal",
        end:true,
        width:"full-w",
        height:"full-H",
      },
    ],
    a:{text:"View more", url:"dopegood"},
    img:{
      cover:"",
      colors:{right: "#000000", left:"#FF9673bf"},
      url:dopegood
    }
  },
  {
    id:"p15i55",
    name: 'AM-Arc',
    desc: 'Architectural design studio',
    tags:['Interior', '3D Rendering','3DRendering',],
    family:"amarc",
    elements:[
      {
        id:"vt15",
        type:"textbox",
        h3:"AM-ARC",
        mt:true,
        h1:"Architectural design & visualization studio",
        width:"short",
        alignment:"alignment",
      },
      {
        id:"vc15",
        mt:true,
        type:"cover",
        double:false,
        src:a1,
        width:"full-w",
        height:"full-H",
      },
      {
        id:"vt25",
        type:"textbox",
        h3:"Expectaion",
        pi:"Designing and developing a modern and minimal website, with multiple features, such as architectural virtual reality tours and animations, for a studio with high design expectations was a tremendously enjoyable experience for us.",
        width:"long",
        butt:"https://am-arc.com"
      
      },
      {
        id:"vb11",
        type:"butsec",
        // double:false,
        url:"https://am-arc.com",
        width:"full-w",
        height:"full-H",
        src:[a2, amarc],
      },
      {
        id:"vc35",
        type:"cover",
        src:[a3,a4],
        double:true,
        align:"space between",
        width:"full-w",
        h2:"Front-end development",
      },
      {
        id:"vt35",
        type:"textbox",
        pi:"We have decided to use smooth scrolling to create a more appealing user experience and add some additional animation to the overall design. This feature was a perfect match for the rest of the website since their studio also provides animation services.",
        width:"long"
      },
      {
        id:"vc45",
        type:"cover",
        double:false,
        src:a5,
        width:"full-w",
        height:"full-H",
      },
      {
        id:"vt45",
        type:"textbox",
        double:true,
        h2width:"mid",
        h2:"Challenges and technologies",
        pi:"Maintaining performance while creating complex animations is challenging using React, smooth scroll, and Gsap together. Several custom animation codes were implemented to avoid memory leaks and achieve the best performance possible.",
      }, 
      {
        id:"vc65",
        type:"cover",
        src:a7,
        double:false,
        width:"full-w",
        height:"full-H",
        end:true,
        pb:"pd",
        // align:"normal",
      }, 
      {
        id:"vc55",
        type:"cover",
        src:a6,
        double:false,
        width:"full-w",
        height:"full-H",
        end:true,
        // align:"normal",
      },
    ],
    a:{text:"View more", url:"amarc"},
    img:{
      cover:"",
      colors:{right: "#000000", left:"#F2AB79bf"},
      url:amarc
    }
  },
  // {
  //   id:"p15i22",
  //   name: 'Comfeey',
  //   desc: 'Furniture brand, Ecommerce website',
  //   tags:['3D Model'],
  //   family:"comfeey",
  //   elements:[
  //     {
  //       id:"vt12",
  //       type:"textbox",
  //       h3:"Comfeey",
  //       mt:true,
  //       h1:"Modern Furniture brand, Ecommerce website",
  //       width:"short",
  //       alignment:"alignment",
  //     },
  //     {
  //       id:"vc12",
  //       mt:true,
  //       type:"cover",
  //       double:false,
  //       width:"full-w",
  //       height:"full-H",
  //     },
  //     {
  //       id:"vt22",
  //       type:"textbox",
  //       h3:"Expectaion",
  //       pi:"Essentially, the purpose of an Ecommerce site is to sell products and gain more customers, so this has been our main focus and we have achieved this by creating a modern and minimalistic design. Since it enables faster download speeds, easy maintenance, easier navigation, and utilizes fewer server resources.",
  //       width:"long",
      
  //     },
  //     // {
  //     //   id:"vc22",
  //     //   type:"cover",
  //     //   double:false,
  //     //   width:"medium-w",
  //     //   height:"medium-h",
  //     // },
  //     // {
  //     //   id:"vb12",
  //     //   type:"button",
  //     //   // double:false,
  //     //   url:"https://comfeey.com",
  //     //   width:"medium-w",
  //     //   height:"medium-h",
  //     // },
  //     {
  //       id:"vb11",
  //       type:"butsec",
  //       // double:false,
  //       url:"https://comfeey.com",
  //       width:"medium-w",
  //       height:"medium-h",
  //       // src:vim2,
  //     },
  //     {
  //       id:"vc32",
  //       type:"cover",
  //       double:true,
  //       align:"space between",
  //       width:"full-w",
  //       h2:"Front-end development",
  //       h2width:"full",
  //     },
  //     {
  //       id:"vt32",
  //       type:"textbox",
  //       pi:"A key to any type of project is identifying insights and trends pertinent to front-end development, web design architecture, and design thinking.",
  //       width:"long"
  //     },
  //     {
  //       id:"vc42",
  //       type:"cover",
  //       double:false,
  //       width:"full-w",
  //       height:"full-H",
  //     },
  //     {
  //       id:"vt42",
  //       type:"textbox",
  //       double:true,
  //       h2width:"mid",
  //       h2:"Challenges and tchnologies",
  //       pi:"Comfeey has been built with WordPress and Wocommerce. Typically  WordPress websites are slow and outdated compared to other programming technologies. Therefore, we aimed to improve and provide better performance.",
  //     },  
  //     {
  //       id:"vc52",
  //       type:"cover",
  //       double:true,
  //       align:"normal",
  //       end:true,
  //     },
  //   ],
  //   a:{text:"View more", url:"comfeey"},
  //   img:{
  //     cover:"",
  //     colors:{right: "#000000", left:"#669BCCbf"},
  //     url:comfeey
  //   }
  // },
  
  // {
  //   id:"p18i8",
  //   name: 'animation',
  //   desc: 'Interior design, Rendering',
  //   tags:['Animation'],
  //   family:"Animation",
  //   a:{text:"View more", url:"pr17-1"},
  //   iframeSrc: 'https://www.youtube.com/embed/34hD4u8WFuI',
   
  // },

  
]